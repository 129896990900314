import React, { useEffect } from "react";
import { Waypoint } from "app/components/Waypoint";
import { playlistsViewScreen } from "services/typewriter/segment";
import {
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  ANALYTICS_SCREEN_NAME,
} from "constants/analytics";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { PlaylistCard } from "app/components/PlaylistCard";
import { RouteWrapper } from "../RouteWrapper";
import { useGetPlaylistsSteezy } from "./hooks";
import { PlaylistListContainer } from "../styles";

const Playlists = () => {
  const {
    playlistsSteezy,
    pageInfoPlaylistsSteezy,
    fetchMorePlaylistsSteezy,
    loadingPlaylistsSteezy,
    isFetchingInitialPlaylistsSteezy,
  } = useGetPlaylistsSteezy();

  useEffect(() => {
    playlistsViewScreen({
      screen_name: ANALYTICS_SCREEN_NAME.steezyPlaylists,
      location: ANALYTICS_LOCATION.playlists,
      module: ANALYTICS_MODULE.steezy_playlists,
    });
  }, []);

  if (isFetchingInitialPlaylistsSteezy) {
    return <LoaderFullCentered />;
  }

  return (
    <PlaylistListContainer>
      {playlistsSteezy &&
        playlistsSteezy.map(playlist => (
          <PlaylistCard playlistData={playlist} key={playlist.id} />
        ))}
      {!loadingPlaylistsSteezy && (
        <Waypoint
          hasMore={pageInfoPlaylistsSteezy?.hasNextPage}
          fetchData={fetchMorePlaylistsSteezy}
        />
      )}
    </PlaylistListContainer>
  );
};

export default () => {
  return (
    <RouteWrapper>
      <Playlists />
    </RouteWrapper>
  );
};
